import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Betrfomt, based on that `}<a parentName="h2" {...{
        "href": "https://www.youtube.com/watch?v=H333yyMqudU"
      }}>{`Silly Youtube Video`}</a>{`.`}</h2>
    <p>{`Sphinx of Black Quartz, judge my vow.`}</p>
    <p>{`𝜋 = 3.14159265358979323846264338327950`}</p>
    <p>{`If you want to download this abomination, it's `}<a parentName="p" {...{
        "href": "/dl/betrfomt.ttf"
      }}>{`available here`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "/"
      }}>{`Take me back to sanity, please!!`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      